<template>
    <div class="cashout">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>开票记录</span>
                </div>
            </template>
            <div>
                <div class="content-header" style="margin-bottom:17px;">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right:10px;" size="small" :default-time="defaulttime"></el-date-picker>
                                <el-input v-model="keyword"  placeholder="请输入公司名" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <!-- <div style="padding:20px 20px 0 0;width:30%;display:flex;justify-content:space-between;">
                    <span>提现中的总金额：{{ongoing/100}}元</span><span>提现完成的总金额：{{complete/100}}元</span>
                </div> -->
                        <el-table :data="tabledata" border size="small">
                            <el-table-column type="index" label="序号" align="center"></el-table-column>
                            <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                            <el-table-column label="公司名称" prop="companyname"> 
                                <template #default="scope">
                                    {{ scope.row.companyname }}
                                </template>
                            </el-table-column>
                            <el-table-column label="纳税人识别号" prop="taxpayer">
                                <template #default="scope">
                                    {{ scope.row.taxpayer }}
                                </template>
                            </el-table-column>

                            <el-table-column label="开票金额" prop="money" >
                                <template #default='scope'>
                                    <span style="color:#FF0000">¥{{scope.row.money*1}}元</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="邮箱" prop="email">
                                <template #default="scope">
                                    {{ scope.row.email }}
                                </template>
                            </el-table-column>
                            <el-table-column label="开票类型" prop="invoicetype">
                                <template #default="scope">
                                    <span v-if="scope.row.invoicetype">电子专票</span>
                                    <span v-else>电子普票</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="申请时间" prop="addtime" align="center" width="150px">
                                <template #default='scope'>
                                    {{scope.row.addtime}}
                                </template>
                            </el-table-column>
                            <el-table-column label="开票状态" prop="status" align="center" width="150px">
                                <template #default='scope'>
                                    <span v-if="scope.row.status == 1">未开票</span>
                                    <span v-if="scope.row.status == 2">已开票</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" >
                                <template #default='scope'>
                                    <el-button type="text" @click='Complete(scope.row)' v-if="scope.row.status == 1 && ismerchant != 1">完成开票</el-button>
                                    <el-button type="text" @click='lookdetail(scope.row.id)' v-if="scope.row.status == 2 && ismerchant != 1">查看详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
                    
            </div>
        </el-card>
    </div>


    <el-dialog v-model="detail" title="开票详情" width="30%">

        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">公司名称:</el-col>
            <el-col :span="12">{{ companyname }}</el-col>
        </el-row>
        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">纳税人识别号:</el-col>
            <el-col :span="12">{{ taxpayer }}</el-col>
        </el-row>
        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">开票金额:</el-col>
            <el-col :span="12">{{ money }}</el-col>
        </el-row>
        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">邮箱:</el-col>
            <el-col :span="12">{{ email }}</el-col>
        </el-row>
        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">开票时间:</el-col>
            <el-col :span="12">{{ invoicetime }}</el-col>
        </el-row>
        <el-row style="text-align:center;font-size: 18px;padding: 5px 0px;border-bottom:1px solid #dad9d9">
            <el-col :span="12">开票截图:</el-col>
            <el-col :span="12" >
                <el-image
                v-for="item in invoiceimage" :key="item"
                    style="width: 100px; margin-right: 10px;height: 100px"
                    :src="item"
                    :zoom-rate="1.2"
                    :preview-src-list="srcList"
                    :initial-index="4"
                    fit="cover"
                    ></el-image>
            </el-col>
        </el-row>
        
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="detail = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>


    <el-dialog v-model="completecashout" title="完成开票" center width="40%">
        <el-form :model="cashoutform" ref="cashoutform" label-width="150px">
            <el-form-item label="商户名称:">{{cashoutform.Merchant}}</el-form-item>
            <el-form-item label="提现金额:">
                ¥{{cashoutform.money}}元
            </el-form-item>
            <el-form-item label="公司名称:">
                {{cashoutform.companyname}}
            </el-form-item>
            <el-form-item label="纳税人识别号:">
                {{cashoutform.taxpayer}}
            </el-form-item>
            
            <el-form-item label="开票时间:"  :rules="{required:true,message:'必须选择开票时间',trigger:'blur'}">
                <el-date-picker type="datetime" v-model="cashoutform.invoicetime" placeholder="选择日期" size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="发票截图:">
                <el-upload  :action="upload_url"
                            multiple
                            :file-list="FileList"
                            accept="image/*"
                            :on-success="UploadSuccess" 
                            :on-change="UploadChange" 
                            :on-error="UploadError" 
                            :on-exceed="UploadExceed"
                            :on-remove="RemovePic"
                            :on-preview="Preview"
                            list-type="picture-card"
                            :before-upload="CheckFile"
                            :limit="10"
                    >
                    <i class="el-icon-plus"></i>
                    <template #tip>
                        <div class="el-upload__tip">只能上传 jpg/png/pdf 文件,最多上传10张</div>
                    </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img style="width:100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="SaveComplete" >确定</el-button>
            <el-button size="small" @click="CancelComplete">取消</el-button>
        </template>
    </el-dialog>

</template>
<script>
import qs from 'qs'
import Cookies from 'js-cookie'
import constant from '@/constant'
import { ref } from 'vue'

export default {
    data(){
        return {
            detail:ref(false),
            datetimerange:[
                new Date(2022,1,1,0,0,0),
                new Date(2022,1,1,23,59,59)
            ],
            complete:0,
            shortcuts:[
                {
                    text:'今天',
                    value: ()=>{
                        const start = new Date();
                        let ret_start = new Date(start.getFullYear(),start.getMonth(),start.getDate(),0,0,0);
                        let ret_end = new Date(start.getFullYear(),start.getMonth(),start.getDate(),23,59,59);
                        console.log('s'+ret_start);
                        console.log('e'+ret_end);
                        return [ ret_start,ret_end]
                    }
                }
            ],
            srcList:[],
            cashoutdetail:false,
            dialogImageUrl: '',
            dialogVisible: false,
            upload_url:constant.upload_temp_img_url,
            completecashout:false,
            logdialog:false,
            curpage:1,
            pagesize:10,
            totalcount:'',
            range:'',
            keyword:'',
            status:'',
            editcashout:false,
            addcashout:false,
            checkcashout:false,
            tabledata:[],
            item:[],
            merchantname:ref(""),
            taxpayer:ref(""),
            companyname:ref(""),
            money:ref(""),
            email:ref(""),
            invoicetime:ref(""),
            invoiceimage:[],
            cashoutform:{
                MerchantID:this.mid,
                taxpayer:'',
                companyname:'',
                money:0,
                id:'',
                images:[],
                invoicetime:'',
            },
            rule:{
                Account:[{
                    required:true,
                    message:'请输入提现账户',
                    trigger:'blur'
                }],
                AccountName:[{
                    required:true,
                    message:'请输入提现账户姓名',
                    trigger:'blur'
                }],
                Amount:[{
                    required:true,
                    message:'请输入提现金额',
                    trigger:'blur'
                }]
            },
            cashoutinfo:{},
            
            merchants:[],
            merchantid:'',
        }
    },
    methods:{
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        lookdetail(id){
            this.detail = true
            console.log(id)
            this.axios.get(constant.getidinvo,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data)
                if(response.data.code != 200){
                    this.$message.error("参数错误");
                }else{
                    this.taxpayer=response.data.data.taxpayer
                    this.companyname=response.data.data.companyname
                    this.money=response.data.data.money
                    this.email=response.data.data.email
                    this.invoicetime=response.data.data.invoicetime
                    this.invoiceimage= response.data.data.invoiceimage
                    this.srcList = response.data.data.invoiceimage
                }
            })
        },
        addtradeno(){
            this.cashoutform.tradeno.push({
                value:''
            })
        },
        deltradeno(index){
            this.cashoutform.tradeno.splice(index,1);
        },
        Log(id){
            console.log(id);
            this.axios.get(constant.cashoutlog,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.log = response.data;
                this.logdialog = true;
            });
        },
        Edit(id){
            console.log(id);
            this.axios.get(constant.getcashout,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.editcashout = true;
                this.cashoutform = response.data;
            });
        },
        Check(id){
            console.log(id);
            this.axios.get(constant.checkcashout,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.cashoutform = response.data;
                this.cashoutdetail = true;
            });
        },
        Close(){
            this.cashoutdetail = false;
        },
        Complete(row){
            this.completecashout = true
            console.log(row);
            this.item = row
            this.cashoutform.money = row.money
            this.cashoutform.id = row.id
            this.cashoutform.companyname = row.companyname
            this.cashoutform.taxpayer = row.taxpayer
        },
        SaveComplete(){
            if(this.cashoutform.invoicetime.length <= 0){
                this.$message.error("请选择开票时间");
                return false
            }
            if(this.cashoutform.images.length <= 0){
                this.$message.error("必须上传至少一张交易完成截图");
                return false;
            }
            this.$refs['cashoutform'].validate((valid)=>{
                if(valid){
                    console.log(this.cashoutform);
                    this.executing = true;
                    this.axios.post(constant.completeinvoice,qs.stringify(this.cashoutform),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data.code == "200"){
                            this.$message.success("操作成功");
                            this.item.status = 2
                            this.completecashout = false
                        }else{
                            this.$message.error("操作失败");
                        }
                        this.executing = false;
                    });
                }else{
                    return false;
                }
            });
        },
        CancelComplete(){
            this.completecashout = false;
        },
        Update(){
            if(this.cashoutform.Amount*1 > this.cashoutform.Credit*1){
                this.$message.error("提现金额不能超过当前可提现额度");
                return false;
            }
            if(this.cashoutform.Amount*1 < 200 || this.cashoutform.Amount*1 > 50000){
                this.$message.error("提现额度必须在200到50000之间");
                return false;
            }
            this.axios.post(constant.updatecashout,qs.stringify(this.cashoutform),{
                headers:{
                    "content-type":"application/x-www-form-urlencoded"
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == 'OK'){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }else{
                    this.$message.error("操作失败");
                }
            });
        },
        switchtab(){
            console.log(this.status);
            this.axios.get(constant.cashoutlist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:1,
                    pagesize:10,
                    keyword:this.keyword,
                    merchantid:this.merchantid,
                    range:JSON.stringify(this.range),
                    status:this.status,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        init(){
            this.axios.get(constant.invoicelist,{
                headers:{
                    "content-type":"application/sjon"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:this.merchantid,
                    range:this.range,
                    keyword:this.keyword,
                    status:this.status,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.data.list;
                this.curpage = response.data.data.curpage*1;
                this.totalcount = response.data.data.totalcount*1;
            });
        },
        add(){
            this.axios.get(constant.addcashout,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    merchantid:this.mid
                }
            }).then((response)=>{
                console.log(response.data);
                let credit = (response.data*1)/100;
                this.cashoutform.Credit = credit;
                this.addcashout = true;
            });
            console.log(this.cashoutform);
        },
        CancelAdd(){
            this.addcashout = false;
            this.editcashout = false;
        },
        Confirm(){
            console.log("a" + this.cashoutform.Amount + "c" + this.cashoutform.Credit);
            //return false;
            
            if(this.cashoutform.Amount*1 < 0.1 || this.cashoutform.Amount*1 > 50000){
                this.$message.error("不符合提现需求");
                return false;
            }
            if(this.cashoutform.Amount*1 > this.cashoutform.Credit*1){
                this.$message.error("不能超过可提现额度");
                return false;
            }
            
            this.$refs['cashoutform'].validate((valid)=>{
                if(valid){
                    console.log(this.cashoutform);
                    
                    this.axios.post(constant.savecashout,qs.stringify(this.cashoutform),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        },
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }else if(response.data == 'HAVEONGOING'){
                            this.$message.error("还有提现中的申请,请等待提现完成之后再进行操作");
                        }else if(response.data == 'NOAMOUNT'){
                            this.$message.error("提现金额不能为0");
                        }
                    });
                }else{
                    return false;
                }
            });
        },
        formatDate(date){
            var datetime = new Date(date*1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2); 
            var second = ("0" + datetime.getSeconds()).slice(-2); 
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        UploadExceed(file,filelist){
            this.$message.warning(`当前限制选择 10 个图片,本次选择了 ${file.length} 个文件,共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response,file,filelist){
            console.log(file);
            console.log(filelist);
            console.log(response);
            if(response.error == '0'){
               this.cashoutform.images = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.cashoutform.images.push(obj);
                }
            }
            console.log(this.cashoutform.images);
        },
        RemovePic(file,filelist){
            console.log(file);
            console.log(filelist);
            this.cashoutform.images = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = filelist[i]['url'];
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.cashoutform.images.push(obj);
                }
        },
        CheckFile(file){
            console.log(file);
            if(file.type != "image/jpg" && file.type !="image/png" && file.type != "image/jpeg"){
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
        },
        UploadError(err){
            console.log(err);
            this.$message.error(err);
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.error == 0){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("id");
        if(this.mid){
            this.cashoutform.MerchantID = this.mid;
            console.log("mid--" + this.mid);
            this.merchantid = this.mid;
        }
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.el-form-item {
    margin-bottom: 15px;
}
.info {
    padding: 5px 10px;
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.info * {
    padding: 2px;
}
</style>